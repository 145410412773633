import { ConfigCatOptions, debugLogger, defaultOptions } from '@nutrien/minesight-utility-module';
import { createFlagOverridesFromMap, OverrideBehaviour } from 'configcat-js';

import { getDefaultFeatureFlags } from './defaultFeatureFlags';

export const options: ConfigCatOptions = {
  ...defaultOptions,
  flagOverrides: createFlagOverridesFromMap(
    getDefaultFeatureFlags(),
    OverrideBehaviour.RemoteOverLocal,
  ),
};

export const debugOptions: ConfigCatOptions = {
  ...options,
  flagOverrides: createFlagOverridesFromMap(
    getDefaultFeatureFlags(),
    OverrideBehaviour.LocalOverRemote,
  ),
  logger: debugLogger,
};

export function getOptions(): ConfigCatOptions {
  if (localStorage.getItem('DEBUG') === 'true') {
    return debugOptions;
  }
  return options;
}
