import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AutoComplete,
  CustomPalette,
  ExpansionPanel,
  i18n,
  TextField,
} from '@nutrien/cxp-components';
import { translate, useSiteFeatures } from '@nutrien/minesight-utility-module';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { PopulatedLocation } from '@/rxdb/Locations/useLocations';
import { sortRoomOptions } from '@/utilities/sortRoomOptions';

import { PassDocument } from '../../rxdb/Passes/queryBuilder';
import { SequenceDocument } from '../../rxdb/Sequences/queryBuilder';
import { useSite } from '../../rxdb/Site/useSite';
import { SurveyPointDocument } from '../../rxdb/SurveyPoints/queryBuilder';
import { HazardPanelTypes } from '../../utilities';
import { MiningMethod } from '../../utilities/constants';
import { sortWithNumberStringsByProperty } from '../../utilities/sortHelper';

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
    },
  }),
);

interface Props {
  roomOptions: PopulatedLocation[];
  onRoomChanged: (room: PopulatedLocation) => void;
  selectedRoom?: PopulatedLocation;
  surveyPointOptions: SurveyPointDocument[];
  onSurveyPointChanged: (surveyPoint: SurveyPointDocument) => void;
  selectedSurveyPoint?: SurveyPointDocument;
  sequences: SequenceDocument[];
  selectedSequence?: SequenceDocument;
  onSequenceChanged: (surveyPoint: SequenceDocument) => void;
  passes: PassDocument[];
  selectedPass?: PassDocument;
  onPassChanged: (pass: PassDocument) => void;
  startDistance: string;
  onStartDistanceChanged: (value: string) => void;
  startDistanceErrorText?: string;
  validationIndex: number;
  setValidationIndex: Dispatch<SetStateAction<number>>;
  expanded: boolean;
  currentExpandedPanelId: HazardPanelTypes | undefined;
  onExpanded: (isExpanded: boolean, expandedPanelId: HazardPanelTypes) => void;
  startHazardLandmark: string;
  setStartHazardLandmark: Dispatch<SetStateAction<string>>;
}

const LocationDetailsPanel: React.FC<Props> = ({
  roomOptions,
  onRoomChanged,
  selectedRoom,
  surveyPointOptions,
  onSurveyPointChanged,
  selectedSurveyPoint,
  sequences,
  selectedSequence,
  onSequenceChanged,
  passes,
  selectedPass,
  onPassChanged,
  startDistance,
  onStartDistanceChanged,
  startDistanceErrorText,
  validationIndex,
  setValidationIndex,
  expanded,
  onExpanded,
  startHazardLandmark,
  setStartHazardLandmark,
  currentExpandedPanelId,
}: Props) => {
  const classes = useStyles();
  const { isLanigan, isCory, isVanscoy, isRocanville, isAllan } = useSiteFeatures();
  const { distanceUnitAbbreviation } = useSite();

  const [errors, setErrors] = useState({
    room: '',
    sequence: '',
    step: '',
    startDistance: '',
  });

  const hasErrors = Object.values(errors).find(error => error !== '') !== undefined;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    setIsEditing(false);
  }, [currentExpandedPanelId]);

  useEffect(() => {
    if (validationIndex >= 3) {
      if (!selectedRoom) {
        setErrors(prev => ({ ...prev, room: 'Required.' }));
        setIsEditing(true);
      } else {
        setErrors(prev => ({ ...prev, room: '' }));
      }
    }
  }, [validationIndex, selectedRoom]);

  useEffect(() => {
    if (validationIndex >= 4) {
      if (surveyPointOptions.length > 0 && !selectedSurveyPoint) {
        setErrors(prev => ({ ...prev, sequence: 'Required.' }));
        setIsEditing(true);
      } else {
        setErrors(prev => ({ ...prev, sequence: '' }));
      }
    }
  }, [validationIndex, selectedSurveyPoint, surveyPointOptions]);

  useEffect(() => {
    if (validationIndex >= 5) {
      // Optional in Cory
      if (
        !selectedSequence &&
        !isCory &&
        (isLanigan || selectedRoom?.panel?.miningMethod === MiningMethod.CHEVRON)
      ) {
        setErrors(prev => ({ ...prev, step: 'Required.' }));
        setIsEditing(true);
      } else {
        setErrors(prev => ({ ...prev, step: '' }));
      }
    }
  }, [validationIndex, selectedSequence, selectedRoom, isCory, isLanigan]);

  useEffect(() => {
    if (validationIndex >= 6) {
      if (startDistance === '') {
        setErrors(prev => ({ ...prev, startDistance: 'Required.' }));
      } else {
        setErrors(prev => ({ ...prev, startDistance: '' }));
      }
    }
  }, [validationIndex, startDistance]);

  const onPanelChange = (event: React.ChangeEvent<unknown>, isExpanding: boolean) => {
    onExpanded(isExpanding, HazardPanelTypes.LOCATION);
    if (!isExpanding) {
      setValidationIndex(prev => (prev > 6 ? prev : 6));
    } else {
      setValidationIndex(prev => (prev > 2 ? prev : 2));
    }
  };

  // Validate Room
  const validateRoomChange = (value: PopulatedLocation) => {
    onRoomChanged(value);
    setValidationIndex(prev => (prev > 3 ? prev : 3));
  };

  // Validate Sequence (SurveyPoint in BE)
  const validateSequenceChange = (value: SurveyPointDocument) => {
    onSurveyPointChanged(value);
    setValidationIndex(prev => (prev > 4 ? prev : 4));
  };

  // Validate Step (Sequence in BE)
  const validateStepChange = (value: SequenceDocument) => {
    onSequenceChanged(value);
    setValidationIndex(prev => (prev > 5 ? prev : 5));
  };

  // Validate Pass (Step in BE)
  const validatePassChange = (value: PassDocument) => {
    onPassChanged(value);
    setValidationIndex(prev => (prev > 6 ? prev : 6));
  };

  // Validate Step (Sequence in BE)
  const validateStartDistanceChange = () => {
    setValidationIndex(prev => (prev > 7 ? prev : 7));
    setIsEditing(false);
  };

  const roomOptionLabel = (option?: PopulatedLocation) => {
    if (!option?.panel) return '';

    if (isLanigan)
      return `${option?.block?.description}${option?.panel?.description}${option?.room?.description}`; // In Lanigan we have a BlockPanelRoom string
    if (isRocanville || (isVanscoy && option.panel?.miningMethod === MiningMethod.LONG_ROOM)) {
      return `${option?.panel?.description}-${option?.room?.description}`; // In Vanscoy Long we have a PanelRoom string
    }

    // Cory/Vanscoy/Allan Chevron have only Panel description
    return `${option?.panel?.description}`;
  };

  const inputXSSize = isVanscoy || isRocanville ? 6 : 12;
  const startDistanceLabel =
    isCory || isVanscoy || isAllan || isRocanville ? i18n.t('Distance') : i18n.t('Start distance');

  return (
    <ExpansionPanel
      className={classes.panel}
      title={i18n.t('Location')}
      key="hazard-location"
      data-testid="LocationDetailsPanel"
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded || hasErrors || isEditing}
      onChange={onPanelChange}
      panelContent={
        <Grid container spacing={2}>
          <Grid item xs={inputXSSize}>
            <AutoComplete
              list={sortRoomOptions(roomOptions)}
              label={isLanigan || isRocanville ? i18n.t('Room') : i18n.t('Panel')}
              required
              getOptionLabel={roomOptionLabel}
              onChange={(event, value) => validateRoomChange(value)}
              value={selectedRoom}
              showCaret
              data-cy="RoomInput"
              data-testid="location-room-auto-complete"
              error={errors.room !== ''}
              errorText={errors.room}
              id="location-room-auto-complete"
            />
          </Grid>
          {surveyPointOptions.length > 0 && !isRocanville && (
            <Grid item xs={inputXSSize}>
              <AutoComplete
                list={sortWithNumberStringsByProperty(surveyPointOptions, 'description')}
                label={translate('Sequence')} // V: Section
                required
                getOptionLabel={(option: SurveyPointDocument) => {
                  return option.description;
                }}
                onChange={(event, value) => validateSequenceChange(value)}
                value={selectedSurveyPoint}
                showCaret
                data-testid="location-survey-point-auto-complete"
                error={errors.sequence !== ''}
                errorText={errors.sequence}
                id="location-survey-point-auto-complete"
              />
            </Grid>
          )}
          {selectedRoom && (
            <>
              {(isLanigan ||
                isAllan ||
                isCory ||
                (isVanscoy && selectedRoom?.panel?.miningMethod === MiningMethod.CHEVRON)) && (
                <Grid item xs={inputXSSize}>
                  <AutoComplete
                    list={sortWithNumberStringsByProperty(sequences, 'description')}
                    label={
                      isLanigan
                        ? i18n.t('Step')
                        : isVanscoy || isAllan
                        ? i18n.t('Sequence')
                        : i18n.t('Sequence (Optional)')
                    } // Cory / V-CH: Sequence // V-LR: Hidden // L: Step // A: Step
                    required
                    getOptionLabel={(sequence: SequenceDocument) => sequence.description}
                    onChange={(event, value) => validateStepChange(value)}
                    value={selectedSequence}
                    showCaret
                    data-testid="location-chevron-sequence-auto-complete"
                    error={errors.step !== ''}
                    errorText={errors.step}
                    id="location-chevron-sequence-auto-complete"
                  />
                </Grid>
              )}
              <Grid item xs={inputXSSize}>
                <AutoComplete
                  list={sortWithNumberStringsByProperty(passes, 'description')}
                  label={i18n.t('Pass (optional)')}
                  required
                  getOptionLabel={(pass: PassDocument) => {
                    return pass.description;
                  }}
                  onChange={(event, value) => validatePassChange(value)}
                  onFocus={() => {
                    setIsEditing(true);
                  }}
                  value={selectedPass}
                  showCaret
                  data-testid="location-pass-auto-complete"
                  autoSelect={false}
                  id="location-long-room-pass-auto-complete"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={startDistanceLabel}
                  required
                  unitText={distanceUnitAbbreviation || ''}
                  onChange={event => onStartDistanceChanged(event.target.value)}
                  value={startDistance}
                  inputProps={{ inputMode: 'numeric', 'data-testid': 'location-start-distance' }}
                  error={startDistanceErrorText !== '' || errors.startDistance !== ''}
                  errorText={startDistanceErrorText || errors.startDistance}
                  onFocus={() => {
                    setIsEditing(true);
                  }}
                  onBlur={validateStartDistanceChange}
                  id="location-distance"
                />
              </Grid>
              {isCory && (
                <Grid item xs={12}>
                  <TextField
                    label={i18n.t('Spad info (optional)')}
                    required
                    onChange={event => setStartHazardLandmark(event.target.value)}
                    value={startHazardLandmark}
                    data-cy="startHazardLandmark-input"
                    id="startHazardLandmark-input"
                    onFocus={() => {
                      setIsEditing(true);
                    }}
                    onBlur={() => {
                      setIsEditing(false);
                    }}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      }
    />
  );
};

export default LocationDetailsPanel;
