import { useCallback, useEffect, useState } from 'react';
import { useRxData } from 'rxdb-hooks';

import { useFlags } from '@/featureFlags/useFlags';
import { useBorerDocs } from '@/rxdb/BorerDocuments/useBorerDocs';
import {
  DocumentUpload,
  DocumentUploadDocument,
  EnrichedDocument,
} from '@/rxdb/Documents/queryBuilder';
import { useDocsForLocation } from '@/rxdb/Documents/useDocsForLocation';
import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';
import { RxdbCollectionName } from '@/rxdb/rxdbCollectionName';
import { currentDateIsWithin } from '@/utilities/dateHelpers';

export const useSafeWorkPlanDocument = () => {
  const [safeWorkPlanDocument, setSafeWorkPlanDocument] = useState<
    EnrichedDocument[] | DocumentUploadDocument[]
  >();

  const { currentBorer, currentRoom, currentSurveyPoint, miningMethod } = useCurrentBorer();
  const { borerDocs } = useBorerDocs(currentBorer?.id);
  const { documentsV2, documentsV2Borer, documentsV2Location } = useFlags();
  const { docsForLocation: currentLocationDocs, docsForLocationLoaded } = useDocsForLocation(
    currentRoom?.id,
    currentSurveyPoint?.description,
  );
  const sWPDocQueryConstructor = useCallback(
    collection =>
      collection.find({
        selector: {
          description: 'Safe Work Plan',
        },
      }),
    [miningMethod],
  );

  const { result: sWPDocTypeArray, isFetching: isDocTypeFetching } = useRxData<DocumentUpload>(
    RxdbCollectionName.DOCUMENT_TYPES,
    sWPDocQueryConstructor,
  );

  useEffect(() => {
    const findCorrectSwpDoc = async () => {
      const sWPDocTypeIds = sWPDocTypeArray.map(x => x.id);

      const matchingDocs = [];
      if (documentsV2) {
        if (documentsV2Borer) {
          const matches = borerDocs
            ?.filter(doc => sWPDocTypeIds.includes(doc.borerDocumentTypeId || ''))
            .filter(doc => currentDateIsWithin(doc.startDate, doc.endDate));
          if (matches) matchingDocs.push(...matches);
        }

        if (documentsV2Location) {
          const matches = currentLocationDocs?.filter(doc => {
            return (
              doc.documentTypeDescription ===
              sWPDocTypeArray.find(x => x.miningMethod === miningMethod)?.description
            );
          });

          if (matches) matchingDocs.push(...matches);
        }
      } else {
        const matchingDoc = currentLocationDocs?.find(locDoc => {
          return (
            locDoc.documentTypeDescription ===
            sWPDocTypeArray.find(x => x.miningMethod === miningMethod)?.description
          );
        });
        if (matchingDoc) matchingDocs.push(matchingDoc);
      }

      if (matchingDocs.length) {
        setSafeWorkPlanDocument(matchingDocs);
      } else {
        setSafeWorkPlanDocument(undefined);
      }
    };
    if (sWPDocTypeArray.length) {
      findCorrectSwpDoc();
    } else {
      setSafeWorkPlanDocument(undefined);
    }
  }, [currentLocationDocs, sWPDocTypeArray]);

  return {
    safeWorkPlanDocument,
    safeWorkPlanDocumentAvailable: Boolean(safeWorkPlanDocument && safeWorkPlanDocument.length),
    safeWorkPlanDocumentFetching: isDocTypeFetching || !docsForLocationLoaded,
  };
};

export default useSafeWorkPlanDocument;
