import './index.css';
import './init';
import './wdyr'; // <--- first import

import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { configure } from '@nutrien/cxp-api-fetch-module';
import { ColorThemeWrapper, darkTheme } from '@nutrien/cxp-components';
import { installWithResolvers } from '@nutrien/minesight-utility-module';
import * as Sentry from '@sentry/react';
import { Amplify } from 'aws-amplify';
import { render } from 'react-dom';

import awsconfig from './aws-exports';
import App from './components/App';
import SnackBarWrapper from './components/AppBar/SnackbarWrapper';
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback/ErrorBoundaryFallback';
import { ConfigCatFeatureFlags } from './featureFlags/ConfigCatFeatureFlags';
import { MSTProvider, rootStore } from './mobx-models/Root';
import { RxDBHandler } from './rxdb/useRxDB';
import { isJestOrStorybook } from './test-helpers/isJestOrStorybook';
import { OnlineStatusProvider } from './utilities/hooks/useOnlineStatus';
import { beforeSend } from './utilities/sentryFunctions';

installWithResolvers();

const { VITE_APP_VERSION } = import.meta.env;
const { VITE_REACT_APP_ENV } = import.meta.env;
const { VITE_SENTRY_SAMPLE_RATE } = import.meta.env;
const { VITE_SENTRY_DSN } = import.meta.env;

const { VITE_SENTRY_REPLAY_ERROR_SAMPLE_RATE } = import.meta.env;

Sentry.init({
  dsn: VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: true,
      networkDetailAllowUrls: [window.location.origin],
      networkCaptureBodies: true,
    }),
  ],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: parseFloat(VITE_SENTRY_REPLAY_ERROR_SAMPLE_RATE || '0'),
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(VITE_SENTRY_SAMPLE_RATE || '0'),
  environment: VITE_REACT_APP_ENV,
  ignoreErrors: [
    'UnhandledRejection: Non-Error promise rejection captured with value: No current user',
  ],
  sampleRate: parseFloat(VITE_SENTRY_SAMPLE_RATE || '0'),
  autoSessionTracking: true,
  release: `minesight-borer@${VITE_APP_VERSION}`,
  // Prevents messages from being truncated
  maxValueLength: 600,
  beforeSend,
  // offline support
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  transportOptions: {
    maxQueueSize: 100,
  },
});

Sentry.setContext('version', { version: VITE_APP_VERSION || 'Unknown' });

if (!isJestOrStorybook()) {
  Amplify.configure(awsconfig);
  configure({
    awsRegion: awsconfig.aws_cognito_region,
    cognitoRegion: awsconfig.aws_cognito_region,
    userPoolId: awsconfig.aws_user_pools_id,
    userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,
  });
}

if (localStorage.getItem('DEBUG_AMPLIFY') !== null) Amplify.Logger.LOG_LEVEL = 'DEBUG';

(async () => {
  render(
    <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
      <ConfigCatFeatureFlags>
        <MSTProvider value={rootStore}>
          <OnlineStatusProvider>
            <RxDBHandler>
              <ThemeProvider theme={darkTheme}>
                <ColorThemeWrapper darkmode>
                  <CssBaseline />
                  <SnackBarWrapper>
                    <App />
                  </SnackBarWrapper>
                </ColorThemeWrapper>
              </ThemeProvider>
            </RxDBHandler>
          </OnlineStatusProvider>
        </MSTProvider>
      </ConfigCatFeatureFlags>
    </Sentry.ErrorBoundary>,
    document.getElementById('root'),
  );
})();
