import * as Sentry from '@sentry/react';
import { flow, getParent, Instance, types } from 'mobx-state-tree';

import { createRegistration } from '@/api/mTlsAuthenticationApi';
import { selectedBorerIdKey } from '@/mobx-models/Equipment/selectedBorerIdKey';

import { listBorers, ListBorersResult } from '../../graphql/Equipment';
import { ApiStatus } from '../../utilities/apiStatus';
import {
  ApiStatusModel,
  BORER_PAGINATION_LIMIT,
  EMPTY_GUID,
  USER_EMAIL_ID,
} from '../../utilities/constants';
import { sortAlphabeticallyByProperty } from './../../utilities/sortHelper';

const MiningMethodModel = types.model({
  layer: types.string,
  name: types.string,
  zone: types.string,
});

const BlockModel = types.model({
  description: types.maybeNull(types.string),
});

const PanelModel = types.model({
  id: types.string,
  description: types.maybeNull(types.string),
  miningMethod: types.maybeNull(MiningMethodModel),
  block: types.maybeNull(BlockModel),
});

const env = import.meta.env.VITE_REACT_APP_ENV;

const BorerModel = types
  .model({
    id: types.identifier,
    longName: types.string,
    shortName: types.maybeNull(types.string),
    assignedPanel: types.maybeNull(PanelModel),
    isActive: types.boolean,
    equipmentType: types.maybe(
      types.model({
        id: types.string,
        description: types.string,
      }),
    ),
  })
  .views(self => ({
    get isRehabBorer() {
      return self.equipmentType?.description === 'Rehab Machine';
    },
    get equipmentTypeLabel() {
      return self.equipmentType?.description;
    },
    get name() {
      const baseName = self?.shortName ? `Borer ${self.shortName}` : self.longName;

      if (env === 'prod') return baseName;
      return `${baseName} (${self.assignedPanel?.miningMethod?.name || 'unassigned'})`;
    },
  }));

export type Borer = Instance<typeof BorerModel>;

export const Equipment = types
  .model({
    BorersMap: types.map(BorerModel),
    BorersFetchStatus: types.enumeration('STATUS', [
      ApiStatus.NOT_CALLED,
      ApiStatus.LOADING,
      ApiStatus.ERROR,
      ApiStatus.SUCCESS,
    ]),
    selectedBorerId: types.maybe(types.string),
    CreateRegistrationStatus: ApiStatusModel,
  })
  .views(self => ({
    getBorerList(filterByIsActive = true, rehabBorersVisible = false) {
      return sortAlphabeticallyByProperty(
        Array.from(self.BorersMap.values())
          .filter(borer => {
            if (filterByIsActive) return borer.isActive;
            return true;
          })
          .filter(borer => {
            if (rehabBorersVisible) return true;
            return !borer.isRehabBorer;
          }),
        'name',
      );
    },
    get selectedBorerAssignedPanel() {
      if (!self.selectedBorerId) return '';
      const borer = self.BorersMap.get(self.selectedBorerId);
      return borer?.assignedPanel;
    },
    get selectedBorerName() {
      if (!self.selectedBorerId) return '';
      const borer = self.BorersMap.get(self.selectedBorerId);

      if (!borer) return '';

      if (borer.shortName) return borer.shortName;
      return borer.longName;
    },
    get selectedBorerMiningMethod() {
      if (!self.selectedBorerId) return '';
      const borer = self.BorersMap.get(self.selectedBorerId);

      if (!borer) return '';

      if (borer.assignedPanel?.miningMethod?.name) {
        return borer.assignedPanel?.miningMethod?.name;
      }
      return '';
    },
    get errorLoadingBorers() {
      return self.BorersFetchStatus === ApiStatus.ERROR;
    },
    get selectedBorerEquipmentTypeId() {
      if (!self.selectedBorerId) return '';
      const borer = self.BorersMap.get(self.selectedBorerId);

      if (!borer) return '';

      if (borer.equipmentType) return borer.equipmentType.id;
    },
  }))
  .actions(self => ({
    fetchBorerList: flow(function* (clear = true) {
      const rootStore = getParent(self, 1);
      const siteId = rootStore.user.siteId;

      if (self.BorersFetchStatus === ApiStatus.LOADING) {
        return;
      }

      let lastId = EMPTY_GUID;
      let borersCount = BORER_PAGINATION_LIMIT;
      self.BorersFetchStatus = ApiStatus.LOADING;
      if (clear) self.BorersMap.clear();

      try {
        while (borersCount === BORER_PAGINATION_LIMIT) {
          const result: ListBorersResult = yield listBorers(siteId, BORER_PAGINATION_LIMIT, lastId);

          const borers = result.data.listEquipmentForType;
          borers.forEach(borer => {
            self.BorersMap.set(borer.id, borer);
          });
          borersCount = borers.length;
          lastId = borers[borersCount - 1]?.id;
        }

        self.BorersFetchStatus = ApiStatus.SUCCESS;

        return self.BorersMap;
      } catch (error) {
        console.log('🚀 ~ file: Equipment.ts:127 ~ fetchBorerList:flow ~ error:', error);
        self.BorersFetchStatus = ApiStatus.ERROR;
      }
    }),
    selectBorer: flow(function* selectBorer(borerId: string, shouldRegister: boolean) {
      self.selectedBorerId = borerId;
      self.CreateRegistrationStatus = ApiStatus.NOT_CALLED;
      const borer = self.BorersMap.get(borerId);

      if (shouldRegister) {
        try {
          const result = yield createRegistration(borerId);
          if (result) {
            self.CreateRegistrationStatus = ApiStatus.SUCCESS;
          }
        } catch (error) {
          console.log('🚀 ~ file: Equipment.ts:174 ~ selectBorer:flow ~ error:', error);
          Sentry.captureException(error, {
            tags: {
              mTlsRegistration: true,
              user: localStorage.getItem(USER_EMAIL_ID),
              borerId,
            },
          });
          self.CreateRegistrationStatus = ApiStatus.ERROR;
        }
      }

      localStorage.setItem(selectedBorerIdKey, JSON.stringify(borer));

      return true;
    }),
  }));

export default Equipment;
