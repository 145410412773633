import { DialogActions, DialogContent, Grid, IconButton, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Button, Card, i18n, Icons, TextField } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { useFlags } from '@/featureFlags/useFlags';
import {
  CuttingPermitSupervisorComment,
  CuttingPermitSupervisorCommentCollection,
} from '@/rxdb/CuttingPermitSupervisorComment/queryBuilder';
import { useViewingCurrentShift } from '@/utilities/hooks/useViewingCurrentShift';

import { useMst } from '../../mobx-models/Root';
import useBorerShift from '../../rxdb/BorerShift/useBorerShift';
import {
  BorerShiftSignature,
  BorerShiftSignatureCollection,
} from '../../rxdb/BorerShiftSignature/queryBuilder';
import useBorerShiftSignatures from '../../rxdb/BorerShiftSignature/useBorerShiftSignatures';
import { Employee } from '../../rxdb/Employees/queryBuilder';
import { useCurrentBorer } from '../../rxdb/Equipment/useCurrentBorer';
import {
  InspectionResult,
  InspectionResultCollection,
} from '../../rxdb/InspectionResults/queryBuilder';
import { CuttingPermitResult } from '../../rxdb/InspectionResults/types';
import useInspections, {
  InspectionDetails,
  InspectionOptionsWithDetails,
} from '../../rxdb/Inspections/useInspections';
import useLocations, { PopulatedLocation } from '../../rxdb/Locations/useLocations';
import RxdbCollectionName from '../../rxdb/rxdbCollectionName';
import { generateBaseEntityWithCreatedOn } from '../../rxdb/rxdbUtilityFunctions';
import { Signature, SignatureCollection } from '../../rxdb/Signature/rxdbSignatureDefinition';
import { useDateFormatters } from '../../utilities';
import { InspectionType, MiningMethod } from '../../utilities/constants';
import { HazardSeverity, SignatureContext, SignatureType } from '../../utilities/enums';
import { useNotification } from '../../utilities/hooks/useNotification';
import useModalStyles from '../../utilities/modalStyles';
import { locationDifferences } from '../../utilities/utilityFunctions';
import AddSignatureModal from '../AddSignatureModal';
import ChecksThisShiftSignatureCard from '../ChecksThisShiftSignatureCard';
import CustomBGSnackbar from '../CustomBGSnackbar';
import CuttingPermitCategory from '../CuttingPermitCategory';
import DiscardDraftModal from '../DiscardDraftModal';
import GenericLocationComponent from '../GenericLocationComponent';
import useStyles from './CuttingPermitModal.styles';
import { getSnackbarBackground } from './helpers';
import { SnackbarBackground } from './types';

const UNDO_SNACKBAR_KEY = 'CuttingPermitUndo';
export interface Props {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  inspectionDetailsSource?: InspectionDetails;
  selectedOptionsSource?: Record<string, InspectionOptionsWithDetails>;
  severityLevel?: HazardSeverity;
  signatureId?: string | null;
  createdOn?: string;
  inspectionComment?: string | null;
  inspectionLocation?: PopulatedLocation | null;
  inspectionSupervisorComment?: string | null;
  inspectionResultId?: string;
  forceRehabCuttingPermit: boolean;
}

export const blankLocation: PopulatedLocation = {
  room: null,
  block: null,
  surveyPoint: null,
  sequence: null,
  pass: null,
  panel: null,
  miningMethod: null,
};

interface CuttingPermitSnackbar {
  backgroundColor?: SnackbarBackground;
  text?: HazardSeverity;
}

const CuttingPermitModal: React.FC<Props> = ({
  open,
  onClose,
  onOpen,
  inspectionDetailsSource,
  selectedOptionsSource = {},
  severityLevel,
  signatureId,
  createdOn,
  inspectionComment,
  inspectionLocation,
  inspectionSupervisorComment,
  inspectionResultId,
  forceRehabCuttingPermit,
}: Props) => {
  const isNewPermit = !Boolean(inspectionResultId);
  const classes = useStyles();
  const modalClasses = useModalStyles();
  const { getInspectionFor } = useInspections();
  const { createLocation } = useLocations();
  const { listEligibleSignees } = useBorerShiftSignatures();
  const userViewingCurrentShift = useViewingCurrentShift();

  const { isLanigan, isCory, isVanscoy, isRocanville, isAllan } = useSiteFeatures();
  const {
    errorNotification,
    successNotification,
    closeSnackbar,
    enqueueSnackbar,
    defaultNotification,
  } = useNotification();
  const { formatDateWithDisappearingDate } = useDateFormatters();
  const { borerDisplayCuttingPermitSeverityValue, coryZoneBCuttingPermit } = useFlags();
  type SnackbarOptions = Parameters<typeof enqueueSnackbar>[1];
  const { getCurrentBorerShift } = useBorerShift();
  const { shiftPicker } = useMst();
  const { miningMethod, isRehabBorer } = useCurrentBorer();

  // Controls
  const [canSave, setCanSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [discardOpen, setDiscardOpen] = useState<boolean>(false);
  const [undo, setUndo] = useState(false);
  const [supervisorComment, setSupervisorComment] = useState<string>('');
  const [supervisorComments, setSupervisorComments] = useState<CuttingPermitSupervisorComment[]>(
    [],
  );
  const [comment, setComment] = useState(inspectionComment || '');
  const [hasLocationValidationError, setHasLocationValidationError] = useState(false);
  const { recentBorerLocation } = useCurrentBorer();

  const [location, setLocation] = useState<PopulatedLocation>(inspectionLocation || blankLocation);
  const [initialLocation, setInitialLocation] = useState<PopulatedLocation>(
    inspectionLocation || blankLocation,
  );

  // Inspection Details
  const [inspectionDetails, setInspectionDetails] = useState<InspectionDetails | undefined>(
    inspectionDetailsSource,
  );
  const [selectedOptions, setSelectedOptions] =
    useState<Record<string, InspectionOptionsWithDetails>>(selectedOptionsSource);
  const currentSnackbarId = useRef<HazardSeverity | undefined>(severityLevel);
  // Signature
  const [signatureModalOpen, setSignatureModalOpen] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [nameNotInList, setNameNotInList] = useState<boolean>(false);
  const [selectedCustomName, setSelectedCustomName] = useState<string>('');
  const [reviewedCuttingPermits, setReviewedCuttinPermits] = useState<boolean>(false);
  const [eligibleEmployees, setEligibleEmployees] = useState<Employee[]>([]);

  // collections
  const signatureCollection: SignatureCollection = useRxCollection(RxdbCollectionName.SIGNATURES);
  const borerShiftSignatureCollection: BorerShiftSignatureCollection | null = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_SIGNATURE,
  );
  const inspectionResultCollection: InspectionResultCollection = useRxCollection(
    RxdbCollectionName.INSPECTION_RESULTS,
  );
  const cuttingPermitSupervisorCommentCollection: CuttingPermitSupervisorCommentCollection =
    useRxCollection(RxdbCollectionName.CUTTING_PERMIT_SUPERVISOR_COMMENT);

  const setSignatures = async () => {
    if (inspectionResultId) {
      try {
        const signatures: CuttingPermitSupervisorComment[] = [];
        await cuttingPermitSupervisorCommentCollection
          ?.find({
            selector: {
              inspectionResultId,
            },
          })
          .exec()
          .then(docs => {
            docs.forEach(doc => {
              signatures.push(doc.toJSON());
            });
          });

        setSupervisorComments(signatures);
      } catch (error) {
        console.log('🚀 ~ file: CuttingPermitModal.tsx ~ line 188 ~ setSignatures ~ error', error);
      }
    }
  };

  const setEligibleEmployeeSignees = async () => {
    try {
      const eligibleSignees = await listEligibleSignees(false);
      setEligibleEmployees(eligibleSignees);
    } catch (error) {
      console.log(
        '🚀 ~ file: CuttingPermitModal.tsx ~ line 215 ~ setEligibleEmployeeSignees ~ error',
        error,
      );
    }
  };

  // Configure
  const setup = async () => {
    let inspectionMiningMethod = miningMethod;
    if (!inspectionMiningMethod) {
      errorNotification('Unable to find cutting permit - please check borer assignment');
      onClose();
      return;
    }

    // If the cory zone B cutting permit override flag is set then the mining method is always long room
    if (coryZoneBCuttingPermit) {
      inspectionMiningMethod = MiningMethod.LONG_ROOM;
    }

    try {
      const result = await getInspectionFor(
        isCory && (isRehabBorer || forceRehabCuttingPermit)
          ? InspectionType.REHAB_CUTTING_PERMIT
          : InspectionType.CUTTING_PERMIT,
        inspectionMiningMethod,
      );
      setInspectionDetails(result);
    } catch (error) {
      console.log('🚀 ~ file: CuttingPermitModal.tsx ~ line 177 ~ setup ~ error', error);
    }

    await setSignatures();
    await setEligibleEmployeeSignees();

    if (recentBorerLocation) {
      setLocation(recentBorerLocation);
      setInitialLocation(recentBorerLocation);
    }
  };

  const handleToggleChange = (event: any, value: InspectionOptionsWithDetails) => {
    if (value?.option)
      setSelectedOptions(prev => {
        const { inspectionCategoryId } = value.option;
        return { ...prev, [inspectionCategoryId]: value };
      });
  };

  // Validates the form is filled in
  const validateCanSave = useCallback(() => {
    let validSave = true;

    if ((isVanscoy || isCory || isRocanville) && hasLocationValidationError) validSave = false;
    if (inspectionDetails) {
      const numberOfCategories = inspectionDetails?.categories.length;
      const numberOfSelectedOptions = Object.keys(selectedOptions).length;

      if (numberOfCategories !== numberOfSelectedOptions) validSave = false;
    }
    if (!isNewPermit) {
      validSave = inspectionSupervisorComment
        ? inspectionSupervisorComment !== supervisorComment
        : supervisorComment.length > 0;
    }

    setCanSave(validSave);
  }, [
    inspectionDetails,
    selectedOptions,
    isCory,
    isVanscoy,
    isRocanville,
    hasLocationValidationError,
    supervisorComment,
  ]);

  const triggerNotification = (
    hazardSeverity: HazardSeverity,
    notificationFunction: (notificationText: string, options?: SnackbarOptions) => void,
  ) => {
    if (currentSnackbarId.current !== hazardSeverity) {
      closeSnackbar(currentSnackbarId.current);
    }

    if (!open || Object.keys(selectedOptions).length === 0) return;

    // Error Notification
    notificationFunction(hazardSeverity, {
      persist: true,
      autoHideDuration: null,
      key: hazardSeverity,
    });

    currentSnackbarId.current = hazardSeverity;
  };

  const severityModelOptions = useMemo(
    () =>
      inspectionDetails?.severityModel
        .map(doc => ({
          lowSeverity: doc.lowSeverity,
          highSeverity: doc.highSeverity,
          description: doc.description,
        }))
        .sort((a, b) => b.lowSeverity - a.lowSeverity),
    [inspectionDetails],
  );

  useEffect(() => {
    validateCanSave();
  }, [selectedOptions, validateCanSave]);

  // Generate the complicated set of options based on hazard details
  const listOfCategoriesAndOptions = useMemo(() => {
    if (!inspectionDetails) return [];

    return inspectionDetails.categories
      .sort((a, b) => a.category.displayOrder - b.category.displayOrder)
      .map(category => {
        return (
          <CuttingPermitCategory
            category={category}
            key={category.category.id}
            selectedOptions={selectedOptions}
            handleToggleChange={handleToggleChange}
            viewOnly={!isNewPermit}
            borerDisplayCuttingPermitSeverityValue={borerDisplayCuttingPermitSeverityValue}
            severityModelOptions={severityModelOptions}
          />
        );
      });
  }, [
    inspectionDetails,
    selectedOptions,
    severityModelOptions,
    borerDisplayCuttingPermitSeverityValue,
    isNewPermit,
  ]);

  const clearSignatureData = () => {
    setSelectedEmployee(undefined);
    setSelectedCustomName('');
    setNameNotInList(false);
    setReviewedCuttinPermits(false);
  };

  const saveSignature = async (
    employee: Employee,
    customName: string,
    reviewedCuttingPermitsValue: boolean,
    signatureData: string,
  ) => {
    const currentBorerShift = await getCurrentBorerShift();

    if (currentBorerShift === null) {
      errorNotification("Can't save signature because no borer shift found was found");
      return;
    }

    // 1. Create Signature Document
    const signature: Signature = {
      ...generateBaseEntityWithCreatedOn(),
      signatureType: SignatureType.CUTTING_PERMIT,
      siteEmployeeId: customName ? undefined : employee?.id,
      personnelName: customName,
      signature: signatureData,
    };

    // 2. Create Borer Shift Signature Document
    const borerShiftSignature: BorerShiftSignature = {
      ...generateBaseEntityWithCreatedOn(),
      borerShiftId: currentBorerShift.id,
      signatureId: signature.id,
      reviewedCuttingPermits: reviewedCuttingPermitsValue,
      purposeOfVisit: null,
      reviewedSafeWorkPlan: null,
    };

    // 3. Insert Documents
    if (signatureCollection) {
      signatureCollection.insert(signature);
    }
    if (borerShiftSignatureCollection) {
      borerShiftSignatureCollection.insert(borerShiftSignature);
    }

    return {
      signatureId: signature.id,
      borerShiftSignatureId: borerShiftSignature.id,
    };
  };

  const onUpdateSignature = (
    employee: Employee,
    customName: string,
    employeeNotInList: boolean,
    reviewedCuttingPermitsValue: boolean,
  ) => {
    setSelectedEmployee(employee);
    setSelectedCustomName(customName);
    setNameNotInList(employeeNotInList);
    setReviewedCuttinPermits(reviewedCuttingPermitsValue);
  };

  const updateInspection = async () => {
    setIsSaving(true);
    try {
      const inspectionResult = await inspectionResultCollection
        ?.findOne({
          selector: {
            id: inspectionResultId as string,
          },
        })
        .exec();

      if (inspectionResult) {
        const inspectionDetail = JSON.parse(inspectionResult.inspectionDetail);
        inspectionDetail.supervisorComment = supervisorComment || null;

        //update
        await inspectionResult.update({
          $set: {
            inspectionDetail: JSON.stringify(inspectionDetail),
          },
        });
      } else {
        return errorSaving();
      }
    } catch (error) {
      console.log('🚀 ~ file: CuttingPermitModal.tsx ~ line 387 ~ error', error);
      return errorSaving();
    }

    // Close Modal
    onCloseCuttingPermit();
    successNotification('Cutting permit updated');
  };

  const onSaveCuttingPermit = async () => {
    closeSnackbar(currentSnackbarId.current);
    if (canSave) {
      setSignatureModalOpen(true);
    } else {
      await updateInspection();
    }
  };

  const resetModalDetails = () => {
    setCanSave(false);
    setIsSaving(false);
    setInspectionDetails(undefined);
    setSelectedOptions({});
    setLocation(blankLocation);
    setInitialLocation(blankLocation);
    currentSnackbarId.current = undefined;
    setComment('');
    setSupervisorComment('');
  };

  const resetModalDetailsOnEdit = async () => {
    setCanSave(false);
    setIsSaving(false);
    setInspectionDetails(inspectionDetailsSource);
    setSelectedOptions(selectedOptionsSource);
    setInitialLocation(inspectionLocation || blankLocation);
    setLocation(inspectionLocation || blankLocation);
    currentSnackbarId.current = severityLevel;
    setComment(inspectionComment || '');
    setSupervisorComment('');
    await setSignatures();
    await setEligibleEmployeeSignees();
  };

  const onCloseCuttingPermit = () => {
    closeSnackbar(currentSnackbarId.current);
    resetModalDetails();
    onClose();
  };
  const isSupervisorCommentChanged = () => {
    if (!isNewPermit)
      return inspectionSupervisorComment
        ? inspectionSupervisorComment !== supervisorComment
        : supervisorComment.length > 0;

    return false;
  };

  const onCancelCuttingPermit = () => {
    if (isSupervisorCommentChanged()) {
      setDiscardOpen(true);
    } else if (
      (!isNewPermit || Object.keys(selectedOptions).length === 0) &&
      !locationDifferences(location, initialLocation)
    ) {
      closeSnackbar(currentSnackbarId.current);
      onClose();
    } else if (
      Object.keys(selectedOptions).length > 0 ||
      locationDifferences(location, initialLocation)
    ) {
      setDiscardOpen(true);
    }
  };

  // Handle status notification trigger
  const checkForStatusNotification = useCallback(() => {
    const options = Object.values(selectedOptions);
    let calculatedSeverityScore: number | null = null;
    if (options.length !== 0)
      calculatedSeverityScore = options.reduce((acc, curr) => acc + curr.option.severityValue, 0);

    if (calculatedSeverityScore === null && !!currentSnackbarId.current) {
      closeSnackbar(currentSnackbarId.current);
      return;
    }

    const matchingModel = severityModelOptions?.find(
      option =>
        calculatedSeverityScore >= option.lowSeverity &&
        calculatedSeverityScore <= option.highSeverity,
    ); // inclusive calculation on ranges

    const modelDescription = matchingModel?.description;

    const snackbarDetails: CuttingPermitSnackbar = {
      text: modelDescription as HazardSeverity,
      backgroundColor: getSnackbarBackground(modelDescription),
    };

    if (snackbarDetails.backgroundColor && snackbarDetails.text) {
      triggerNotification(snackbarDetails.text, (txt, opt) => {
        defaultNotification(txt, {
          ...opt,
          content: (key, message) => (
            <CustomBGSnackbar
              id={key as string}
              message={message as string}
              bgColor={snackbarDetails.backgroundColor as string}
            />
          ),
        });
      });
    } else if (currentSnackbarId.current) {
      closeSnackbar(currentSnackbarId.current);
    }
  }, [selectedOptions, severityModelOptions]);

  useEffect(() => {
    checkForStatusNotification();
  }, [checkForStatusNotification]);

  const errorSaving = () => {
    errorNotification(i18n.t('Cutting Permit could not save. Please try again.'));
    setSignatureModalOpen(false);
    checkForStatusNotification();
    setIsSaving(false);
  };

  const onCancelSignature = (showStatusNotification = true) => {
    setSignatureModalOpen(false);
    clearSignatureData();
    if (showStatusNotification) checkForStatusNotification();
  };

  const onSaveSignature = async (
    employee: Employee,
    customName: string,
    cuttingPermitsReviewed: boolean,
    signatureData: string,
  ) => {
    setIsSaving(true);
    closeSnackbar(currentSnackbarId.current);

    // Save the Signature
    let signature;
    try {
      signature = await saveSignature(employee, customName, cuttingPermitsReviewed, signatureData);
    } catch (error) {
      console.log('🚀 ~ file: CuttingPermitModal.tsx ~ line 534 ~ onSaveSignature ~ error', error);
      return errorSaving();
    }

    if (!signature || !inspectionDetails || !shiftPicker.currentBorerShiftId) {
      return errorSaving();
    }

    if (inspectionResultId) {
      const doc: CuttingPermitSupervisorComment = {
        ...generateBaseEntityWithCreatedOn(),
        signatureId: signature.signatureId,
        comment: supervisorComment,
        inspectionResultId,
        modifiedOn: dayjs().toISOString(),
      };

      try {
        await cuttingPermitSupervisorCommentCollection?.insert(doc);
      } catch (error) {
        console.log('🚀 ~ file: CuttingPermitModal.tsx ~ line 576 ~ error', error);
        return errorSaving();
      }
    } else {
      try {
        let locationId = null;
        if (isCory || isVanscoy || isRocanville) {
          if (!location?.panel) throw new Error('Missing critical location elements');
          const newLocation = await createLocation(
            location.panel?.id,
            location.room?.id,
            location.surveyPoint?.id,
            location?.sequence?.id,
            location?.pass?.id,
          );
          locationId = newLocation?.id || null;
        }

        // Generate Inspection Object
        const inspectionResult: CuttingPermitResult = {
          cuttingPermitConfig: inspectionDetails,
          cuttingPermitSelections: selectedOptions,
          cuttingPermitSeverity: currentSnackbarId.current || null,
          comment: comment || null,
          supervisorComment: supervisorComment || null,
        };

        const doc: InspectionResult = {
          ...generateBaseEntityWithCreatedOn(),
          inspectionDetail: JSON.stringify(inspectionResult),
          signatureId: signature.signatureId,
          inspectionId: inspectionDetails.inspection.id,
          borerShiftId: shiftPicker.currentBorerShiftId,
          locationId,
        };

        // Save Inspection
        await inspectionResultCollection?.insert(doc);
      } catch (error) {
        console.log('🚀 ~ file: CuttingPermitModal.tsx ~ line 539 ~ error', error);
        return errorSaving();
      }
    }

    // Close Modal
    onCloseCuttingPermit();
    successNotification('Cutting permit added');
  };

  const onCancelDiscardDraft = () => {
    setDiscardOpen(false);
    setUndo(false);
  };

  const onDiscardDraft = () => {
    onClose();
    closeSnackbar(currentSnackbarId.current);
    setDiscardOpen(false);
    errorNotification(i18n.t('Cutting permit draft discarded'), {
      key: UNDO_SNACKBAR_KEY,
      action: key => (
        <Button
          noMinHeight
          color="primary"
          variant="text"
          onClick={() => {
            setUndo(true);
            closeSnackbar(key);
            onOpen();
          }}
          id="undo-cutting-permit-draft-discard-button"
        >
          {i18n.t('Undo')}
        </Button>
      ),
    });
  };

  useEffect(() => {
    if (open && !undo) {
      if (!isNewPermit) {
        resetModalDetailsOnEdit().then(() => {});
      } else {
        resetModalDetails();
        setup().then(() => {});
      }
    }

    if (open && undo) {
      checkForStatusNotification();
      setUndo(false);
      closeSnackbar(UNDO_SNACKBAR_KEY);
    }
  }, [open, miningMethod]);

  const onLocationChange = (key: keyof PopulatedLocation, value: any) => {
    setLocation(prevState => ({ ...prevState, [key]: value }));
  };

  if (!open) return null;
  return (
    <>
      {open && (
        <div className={modalClasses.root}>
          <MuiDialogTitle disableTypography className={modalClasses.dialogTitleContainer}>
            <div className={modalClasses.headerContainer}>
              {!isNewPermit && (
                <div className={modalClasses.leftHeaderButton}>
                  <Typography variant="h5" color="primary">
                    {formatDateWithDisappearingDate(createdOn)}
                  </Typography>
                </div>
              )}
              <div className={modalClasses.headerTitleContainer}>
                <Typography variant="h6" data-testid="cuttingPermit-modal-name">
                  Cutting permit
                </Typography>
              </div>
              <div className={`${modalClasses.rightButtonContainer} `}>
                <IconButton
                  aria-label="close"
                  className={modalClasses.closeButton}
                  onClick={onCancelCuttingPermit}
                  data-cy="closeDialogButton"
                  id="close-dialog-button"
                >
                  <Icons.XFeather strokeWidth={1} color="primary" />
                </IconButton>
              </div>
            </div>
          </MuiDialogTitle>
          <DialogContent className={modalClasses.dialogContent}>
            {(isCory || isVanscoy || isRocanville) && (
              <Card className={modalClasses.card}>
                <Typography variant="h3">Location</Typography>
                <GenericLocationComponent
                  direction={'row'}
                  isLanigan={isLanigan}
                  isCory={isCory}
                  isVanscoy={isVanscoy}
                  isRocanville={isRocanville}
                  isAllan={isAllan}
                  location={location}
                  isChevron={miningMethod === MiningMethod.CHEVRON}
                  setHasValidationErrors={setHasLocationValidationError}
                  onLocationChange={onLocationChange}
                  viewOnly={!isNewPermit}
                />
              </Card>
            )}
            <Grid container>{listOfCategoriesAndOptions}</Grid>
            {(isCory || isVanscoy || isRocanville || isAllan) && (
              <Card className={modalClasses.card}>
                <Typography variant="h3">Comment (optional)</Typography>
                <TextField
                  multiline
                  rows={isNewPermit ? 3 : undefined}
                  onChange={event => {
                    setComment(event.target.value?.replace(/ /g, '\u00a0') || '');
                  }}
                  value={comment}
                  disabled={!isNewPermit}
                />
              </Card>
            )}
            {!isNewPermit && (
              <Grid container className={classes.commentMargin}>
                <ChecksThisShiftSignatureCard signatureId={signatureId} />
              </Grid>
            )}

            {supervisorComments
              ?.sort((a, b) => a.createdOn.localeCompare(b.createdOn))
              .map(superComment => (
                <React.Fragment key={superComment.id}>
                  {superComment.signatureId && (
                    <Grid container>
                      <ChecksThisShiftSignatureCard
                        label="Supervisor's name"
                        signatureId={superComment.signatureId}
                      />
                    </Grid>
                  )}
                  <Card
                    className={clsx(modalClasses.card, {
                      [classes.commentMargin]: userViewingCurrentShift,
                    })}
                  >
                    <Typography>{superComment.comment.replace(/\u00a0/g, ' ')}</Typography>
                  </Card>
                </React.Fragment>
              ))}

            {!isNewPermit && (
              <Grid container>
                <Grid item xs={12}>
                  <Card
                    className={clsx(modalClasses.card, {
                      [classes.marginPrevShiftBottom]: !userViewingCurrentShift,
                    })}
                  >
                    <Typography variant="h3">Supervisors comment (optional)</Typography>
                    <TextField
                      multiline
                      rows={2}
                      onChange={event => {
                        setSupervisorComment(event.target.value?.replace(/ /g, '\u00a0') || '');
                      }}
                      value={supervisorComment}
                      disabled={!userViewingCurrentShift}
                    />
                  </Card>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions className={modalClasses.dialogActionsContainer}>
            <>
              {userViewingCurrentShift && (
                <Grid container justify="space-between">
                  <Grid item>
                    <Button
                      onClick={onCancelCuttingPermit}
                      variant="outlined"
                      color="primary"
                      noMinHeight
                      id="cancel-cutting-permit-button"
                    >
                      {i18n.t('Cancel')}
                    </Button>
                  </Grid>
                  <Grid item className={classes.marginRight}>
                    <Button
                      onClick={onSaveCuttingPermit}
                      variant="contained"
                      color="primary"
                      noMinHeight
                      disabled={!canSave || isSaving}
                      isLoading={isSaving}
                      id="save-cutting-permit-button"
                    >
                      {i18n.t('Save')}
                    </Button>
                  </Grid>
                </Grid>
              )}
              {!isNewPermit && !userViewingCurrentShift && (
                <Grid container spacing={2} justify="space-between">
                  <Grid item xs={6} />
                  <Grid item className={classes.marginRight}>
                    <Button
                      onClick={onCancelCuttingPermit}
                      variant="contained"
                      color="primary"
                      noMinHeight
                      id="close-cutting-permit-button"
                    >
                      {i18n.t('Close')}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          </DialogActions>
        </div>
      )}
      {signatureModalOpen && (
        <AddSignatureModal
          open={signatureModalOpen}
          type={!isNewPermit ? SignatureType.SUPERVISOR : SignatureType.CUTTING_PERMIT}
          context={SignatureContext.AREA_CHECK}
          onCancel={onCancelSignature}
          onSave={onSaveSignature}
          onUpdate={onUpdateSignature}
          employees={eligibleEmployees} // Only show employees that have signed in this shift
          selectedEmployee={selectedEmployee}
          selectedCustomName={selectedCustomName}
          nameNotInList={nameNotInList}
          reviewedCuttingPermits={reviewedCuttingPermits}
          disableEnforceFocus
          cardText={
            !isNewPermit
              ? i18n.t('Add your signature to verify that you have added the supervisor comment')
              : undefined
          }
        />
      )}
      {discardOpen && (
        <DiscardDraftModal
          open={discardOpen}
          onCancel={onCancelDiscardDraft}
          onDiscard={onDiscardDraft}
          disableEnforceFocus
        />
      )}
    </>
  );
};

export default CuttingPermitModal;
