/* eslint-disable no-await-in-loop */
import './App.css';

import DayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Sentry from '@sentry/react';
import { setContext } from '@sentry/react';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Provider as RXDBProvider } from 'rxdb-hooks';

import { captureInSentryWithDetails } from '@/utilities/captureInSentryWithDetails';
import { getBorerShortName } from '@/utilities/getBorerShortName';
import useEventTracking, {
  getUserEmailForTracking,
  NO_EMAIL_FOUND,
  TrackingEventType,
} from '@/utilities/hooks/useEventTracking';
import { DifferentSiteIdError } from '@/utilities/minesightErrors';

import { version } from '../../../package.json';
import { featureFlagContext } from '../../featureFlags/featureFlagContext';
import { useMst } from '../../mobx-models/Root';
import RxdbCollectionName from '../../rxdb/rxdbCollectionName';
import useRxDB from '../../rxdb/useRxDB';
import { useNotification, useOnlineStatus } from '../../utilities';
import { USER_SITE_ID } from '../../utilities/constants';
import { USER_TIMEZONE } from '../../utilities/hooks/useDateFormatters';
import useInterval from '../../utilities/hooks/useInterval';
import useLastSyncTime from '../../utilities/hooks/useLastSyncTime';
import useWindowVisibility from '../../utilities/hooks/useWindowVisibility';
import {
  getLastBackgroundedTime,
  getLastSyncDiff,
  saveBackgroundTimeToLocalStorage,
} from '../../utilities/syncHelpers';
import AddAreaCheck from '../AddAreaCheck';
import AddWorkFaceAreaInspection from '../AddWorkFaceAreaInspection';
import AppBarController from '../AppBarController';
import AreaCheckPage from '../AreaCheckPage';
import { BorerHeartbeat } from '../BorerHeartbeat/BorerHeartbeat';
import { useBorerHeartbeat } from '../BorerHeartbeat/useBorerHeartbeat';
import CacheUpdateHandler from '../CacheUpdateHandler';
import Home from '../Home';
import Loading from '../Loading';
import Advances from '../pages/Advances';
import Equipment from '../pages/Equipment';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import PiSightPage from '../pages/PiSightPage';
import ShiftEnd from '../pages/ShiftEnd';
import PWAUpdate from '../PWAUpdate';
import ShiftStart from '../ShiftStart';
import Signatures from '../Signatures';
import TabBar from '../TabBar';
import { PING_RESOURCE_BIG } from '../UpdateModal/UpdateModal';

dayjs.extend(timezone);
dayjs.tz.setDefault(USER_TIMEZONE);

const App: React.FC = () => {
  const { db } = useRxDB();
  const featureFlagManager = useContext(featureFlagContext);
  setContext('featureFlags', featureFlagManager.flags);

  const online = useOnlineStatus();
  const { equipment, user } = useMst();

  const lastBorerStateSyncTime = useLastSyncTime(RxdbCollectionName.BORER_OPERATOR_STATE_FEED);
  const windowVisible = useWindowVisibility();
  const { sendBorerHeartbeat } = useBorerHeartbeat();
  const { trackEvent } = useEventTracking();
  const { errorNotification } = useNotification();
  const { location } = window;

  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const fetchFeatureFlagsAndIdentifyInSentry = async () => {
    try {
      const currentSession = await Auth.currentSession();

      if (currentSession?.getIdToken()?.payload?.email) {
        const { email } = currentSession?.getIdToken()?.payload;
        await featureFlagManager.identifyUser(email, {
          mineSightBorer: 'true',
          site: user.getSiteName(),
          siteId: user.siteId || USER_SITE_ID || 'unknown site',
          version,
        });

        Sentry.setUser({ email });
      }
    } catch (err) {
      // Legacy logging
      // eslint-disable-next-line no-console
      console.log('🚀 ~ file: App.tsx:101 ~ fetchFeatureFlagsAndIdentifyInSentry ~ err:', err);
    }
  };

  useEffect(() => {
    // If app is in background for more than 2 minutes, refresh page
    const maxBackgroundBeforeRefresh = 60 * 2; // 2 minutes
    if (windowVisible) {
      const lastBackgroundTime = getLastBackgroundedTime();
      if (lastBackgroundTime && lastBackgroundTime > maxBackgroundBeforeRefresh) {
        db?.stopReplication();
        db?.waitForIdle();
        window.location.reload();
      }
    } else if (windowVisible === false) {
      saveBackgroundTimeToLocalStorage();
    }
  }, [windowVisible, db]);

  // Refresh tokens on 1 hour interval
  useInterval(() => {
    if (db && online) {
      db.refreshTokensOnAllCollections();
    }
  }, 1 * 60 * 60 * 1000);

  const checkIfAuthenticated = async () => {
    if (location.pathname.indexOf('login') !== -1) return; // We already check if authenticated on Login
    try {
      //  Get session (Error thrown if no session exists)
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      await user.getProfile();
      // Setup user in sentry and flag provider
      await fetchFeatureFlagsAndIdentifyInSentry();
    } catch (err) {
      console.log('🚀 ~ file: App.tsx:168 ~ checkIfAuthenticated ~ err:', err);
      if (err?.message === 'Network Error' || err?.message === 'Load Failed') {
        const errDetails = {
          redirectToLogin: false,
          email: getUserEmailForTracking(user?.Profile?.email ?? NO_EMAIL_FOUND),
        };
        await trackEvent(TrackingEventType.AUTHENTICATION_NETWORK_LOSS, {
          err,
          ...errDetails,
        });
        captureInSentryWithDetails(err, errDetails);
        // Don't re-direct to login if network issues / offline
        return;
      }
      if (err === 'The user is not authenticated') {
        // This occurs for several reasons
        // 1. Refresh token expiry
        // 2. No session exists (never logged in)
        // 3. Session revoked from Cognito
        const finalNetworkCheck = await fetch(PING_RESOURCE_BIG, {
          method: 'GET',
        });
        if (finalNetworkCheck.status !== 200) return;
        try {
          // Good network, attempt refresh one last time
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const currentSession = await Auth.currentSession();
          const refreshToken = currentSession.getRefreshToken();
          cognitoUser.refreshSession(refreshToken);
        } catch (e) {
          if (e === 'The user is not authenticated') {
            // Likely due to revoked token
            errorNotification(
              'There was an authentication error, you will be re-directed to the login screen.',
            );
            const errDetails = {
              shortName: getBorerShortName(),
              redirectToLogin: true,
              userNotAuthenticatedError: true,
            };
            await trackEvent(TrackingEventType.BAD_AUTH_TOKEN, {
              err,
              ...errDetails,
            });
            captureInSentryWithDetails(err, errDetails);
            setTimeout(() => {
              location.href = '/logout';
            }, 5000);
          }
        }
        return;
      }
      if (err.code === DifferentSiteIdError.code) {
        errorNotification(DifferentSiteIdError.message);
        await trackEvent(TrackingEventType.MISCONFIGURED_USER, { err, redirectToLogin: true });
        setTimeout(() => {
          location.href = '/logout';
        }, 5000);
        return;
      }
      // Previously would result in redirect to /login
      const errDetails = {
        unknownAuthenticationError: true,
        redirectToLogin: false,
      };
      await trackEvent(TrackingEventType.UNKNOWN_AUTHENTICATION_ERROR, {
        err,
        ...errDetails,
      });
      captureInSentryWithDetails(err, errDetails);
    }
  };

  useEffect(() => {
    if (location.pathname.indexOf('logout') === -1 && windowVisible && online)
      checkIfAuthenticated();

    //  On opening app, check to see if latest data is available
    const recentSyncSeconds = getLastSyncDiff();
    const syncInterval = 3600;

    if (windowVisible && recentSyncSeconds && recentSyncSeconds >= syncInterval && online) {
      (async () => {
        setShowLoadingModal(true);

        if (db) {
          await db.refreshTokensOnAllCollections();
          await db.runSingleReplication();
          setShowLoadingModal(false);
        }
      })();
    } else if (!online) {
      setShowLoadingModal(false);
    }
  }, [windowVisible, online]);

  const handleSuccessfulLogin = async (history: any) => {
    try {
      await fetchFeatureFlagsAndIdentifyInSentry();
      await equipment.fetchBorerList();
      sendBorerHeartbeat({ loginTime: dayjs(), lastUserInteractionTime: dayjs() });
      history.push('/home');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      id="app-root"
      style={{ touchAction: 'none' }}
      onTouchStart={e => {
        e.preventDefault();
      }}
    >
      <RXDBProvider db={db?.db}>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <CacheUpdateHandler />
          <PWAUpdate serviceWorkerFile="sw.js" />
          <Router>
            <BorerHeartbeat />
            <AppBarController loadingModalOpen={showLoadingModal} />
            <Switch>
              <Route path="/initializing" exact>
                <Loading />
              </Route>
              <Route path="/home" exact>
                <Home />
              </Route>
              <Route path="/signatures" exact>
                <Signatures />
              </Route>
              <Route path="/shift-start" exact>
                <ShiftStart />
              </Route>
              <Route path="/area-check" exact>
                <AreaCheckPage />
              </Route>
              <Route path="/add-areacheck" exact>
                <AddAreaCheck />
              </Route>
              <Route path="/add-wfai" exact>
                <AddWorkFaceAreaInspection />
              </Route>
              <Route path="/equipment" exact>
                <Equipment />
              </Route>
              <Route path="/delaysandactivities" exact>
                <PiSightPage lastSyncTime={lastBorerStateSyncTime} />
              </Route>
              <Route path="/advances" exact>
                <Advances />
              </Route>
              <Route path="/shift-end">
                <ShiftEnd />
              </Route>
              <Route path="/login" exact>
                <Login loginHandler={handleSuccessfulLogin} />
              </Route>
              <Route path="/logout" exact>
                <Logout />
              </Route>
              <Route path="/">
                {/* siteId set after initial login and bootstrapped from localStorage */}
                {user.siteId ? <Redirect to="/initializing" /> : <Redirect to="/login" />}
              </Route>
            </Switch>
            <TabBar />
          </Router>
        </MuiPickersUtilsProvider>
      </RXDBProvider>
    </div>
  );
};

export default observer(App);
