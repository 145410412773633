import { selectedBorerIdKey } from '@/mobx-models/Equipment/selectedBorerIdKey';

export const getBorerShortName = (): string | null => {
  // Get the borers name from local storage
  const borerInfo = localStorage.getItem(selectedBorerIdKey);
  let shortName = null;
  if (borerInfo) {
    const borerInfoObj = JSON.parse(borerInfo);
    shortName = borerInfoObj.shortName;
  }

  return shortName;
};
