export function loadConfigCatSdkKeys() {
  const envValue = import.meta.env.VITE_CONFIGCAT_SDK_KEY;
  if (!envValue) {
    // Technically we can still use the app without feature flags from ConfigCat,
    // but it's unexpected and undesirable.
    // eslint-disable-next-line no-console
    console.error('Missing VITE_CONFIGCAT_SDK_KEY env var');
    return [];
  }
  return envValue.split(',');
}
