import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useFlags } from '@/featureFlags/useFlags';

import { useMst } from '../../mobx-models/Root';

// Uses flag value to determine if we should show Work Face Area Inspections
const useBorerWfai = () => {
  const { borerWfaiDate } = useFlags();
  const { user, shiftPicker } = useMst();

  const useWfai = useMemo(() => {
    if (
      borerWfaiDate &&
      borerWfaiDate[user.siteId || ''] &&
      dayjs(shiftPicker.Date).isAfter(dayjs(borerWfaiDate[user.siteId || '']).subtract(1, 'day'))
    ) {
      return true;
    }
    return false;
  }, [user.siteId, borerWfaiDate, shiftPicker.Date]);

  return {
    useWfai,
  };
};

export default useBorerWfai;
