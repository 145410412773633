import { OVERRIDE_SITE_ID_KEY } from '@nutrien/minesight-utility-module';
import * as Sentry from '@sentry/react';

import {
  ALLAN_FIXED_SITE_ID,
  CORY_FIXED_SITE_ID,
  LANIGAN_FIXED_SITE_ID,
  ROCANVILLE_FIXED_SITE_ID,
  VANSCOY_FIXED_SITE_ID,
} from './constants';
import { getBorerShortName } from './getBorerShortName';

const getSiteName = () => {
  const siteId = localStorage.getItem(OVERRIDE_SITE_ID_KEY);
  if (siteId === VANSCOY_FIXED_SITE_ID) {
    return 'Vanscoy';
  }
  if (siteId === CORY_FIXED_SITE_ID) {
    return 'Cory';
  }
  if (siteId === ROCANVILLE_FIXED_SITE_ID) {
    return 'Rocanville';
  }
  if (siteId === ALLAN_FIXED_SITE_ID) {
    return 'Allan';
  }
  if (siteId === LANIGAN_FIXED_SITE_ID) {
    return 'Lanigan';
  }
  return '';
};

export const captureInSentryWithDetails = async (
  err: any,
  tags: { [key: string]: string | boolean | number | null },
) => {
  const detailedTags = {
    shortName: getBorerShortName(),
    siteName: getSiteName(),
    ...tags,
  };

  return Sentry.captureException(err, { tags: detailedTags });
};
