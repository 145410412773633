import { Grid } from '@material-ui/core';
import { Button, Dialog, i18n, Typography } from '@nutrien/cxp-components';
import * as Sentry from '@sentry/react';
import React, { useState } from 'react';

import { createRegistration } from '@/api/mTlsAuthenticationApi';
import { useNotification } from '@/utilities';
import { USER_EMAIL_ID } from '@/utilities/constants';
import useEventTracking, { TrackingEventType } from '@/utilities/hooks/useEventTracking';
import { getBorerId } from '@/utilities/utilityFunctions';

import { useStyles } from './../UserMenuConfirmModal.styles';

interface Props {
  open: boolean;
  onClose: () => void;
}
const RegisterDeviceModal: React.FC<Props> = ({ open, onClose }: Props) => {
  const classes = useStyles();

  const { successNotification, errorNotification } = useNotification();
  const [registerLoading, setRegisterLoading] = useState(false);

  const { trackEvent } = useEventTracking();

  const onRegisterConfirmed = async () => {
    const borerId = getBorerId();
    const emailId = localStorage.getItem(USER_EMAIL_ID);
    try {
      setRegisterLoading(true);

      const result = await createRegistration(borerId);
      if (result) {
        successNotification(i18n.t('Registration successful'));
        await trackEvent(TrackingEventType.MTLS_REGISTRATION_SUCCESS, {
          data: JSON.stringify({
            userName: emailId,
            borerId,
          }),
        });
      }
    } catch (error) {
      console.log('🚀 ~ onRegisterConfirmed ~ e:', error);
      errorNotification(i18n.t('Registration failed'));
      Sentry.captureException(error, {
        tags: {
          mTlsRegistration: true,
          user: emailId,
          borerId,
        },
      });
    }
    setRegisterLoading(false);
    onClose();
  };

  if (!open) return null;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      titleText={i18n.t('Register device')}
      backButton={false}
      disableEnforceFocus={false}
      disableEscapeKeyDown
      disableBackdropClick
      dialogActions={
        <Grid container justify="space-between" spacing={2}>
          <Grid item xs={5}>
            <Button
              onClick={onClose}
              variant="outlined"
              color="primary"
              noMinHeight
              id="cancel-button"
              data-testid={'cancel-button'}
            >
              {i18n.t('Cancel')}
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              onClick={onRegisterConfirmed}
              variant="contained"
              color="primary"
              noMinHeight
              id="register-button"
              isLoading={registerLoading}
              data-testid={'register-button'}
            >
              {i18n.t('Register device')}
            </Button>
          </Grid>
        </Grid>
      }
      maxWidth="xs"
      maxHeight="210px"
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
        style: { touchAction: 'none' },
      }}
    >
      <Typography variant="h6" className={classes.title}>
        {i18n.t('Are you sure?')}
      </Typography>
    </Dialog>
  );
};

export default RegisterDeviceModal;
