/* eslint-disable no-nested-ternary */
import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import {
  LocationDocument,
  PanelDocument,
  Pass,
  Room,
  Sequence,
  SurveyPoint,
} from '@/models/models';
import { Block, BlockCollection } from '@/rxdb/Blocks/queryBuilder';
import { RxdbCollectionName } from '@/rxdb/rxdbCollectionName';
import { useSite } from '@/rxdb/Site/useSite';
import { FEET_PER_METERS, MiningMethod } from '@/utilities/constants';

export const NOT_AVAILABLE = '';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '16px',
    },
    titleLine: {
      marginBottom: '2px',
    },
    dot: {
      padding: '0px 12px',
      color: theme.palette.primary.main,
      fontSize: '18px',
    },
    dot2: {
      padding: '0px 8px',
      color: theme.palette.primary.main,
      fontSize: '14px',
    },
    label: {
      paddingRight: theme.spacing(1),
    },
    secondaryText: {
      color: theme.palette.text.disabled,
    },
    secondaryTextRightPad: {
      color: theme.palette.text.disabled,
      paddingRight: '8px',
    },
    sequencePassText: {
      color: theme.palette.primary.main,
    },
  }),
);

const GreenDotCharacter = () => {
  const classes = useStyles();

  return <span className={classes.dot2}>&#183;</span>;
};

interface Props {
  location: LocationDocument;
  ignoreStepAndPassColors?: boolean;
  hazardLandmark: string | null;
  label?: string;
}

const LocationDetailsComponent: React.FC<Props> = ({
  location,
  ignoreStepAndPassColors = false,
  hazardLandmark,
  label,
}: Props) => {
  const classes = useStyles();
  const { isLanigan, isCory, isVanscoy, isRocanville, isAllan } = useSiteFeatures();
  const { distanceUnitAbbreviation, useMeters } = useSite();

  const [block, setBlock] = useState<Block | undefined>(undefined);
  const [surveyPoint, setSurveyPoint] = useState<SurveyPoint | undefined>(undefined);
  const [sequence, setSequence] = useState<Sequence | undefined>(undefined);
  const [pass, setPass] = useState<Pass | undefined>(undefined);
  const [room, setRoom] = useState<Room | undefined>(undefined);

  const blocksCollection: BlockCollection = useRxCollection(RxdbCollectionName.BLOCKS);

  const panelReferencesQueryConstructor = useCallback(
    (collection: any) => {
      if (location) {
        return collection.findOne().where('id').equals(location.panel);
      }
    },
    [location],
  );

  const { result: panelReferences }: { result: PanelDocument[] } = useRxData(
    RxdbCollectionName.PANELS,
    panelReferencesQueryConstructor,
  );

  const populatePanelReferences = async () => {
    try {
      const panelData = await location.populate('panel');
      const blockData = await blocksCollection
        ?.findOne({
          selector: {
            id: panelData.block,
          },
        })
        .exec();
      if (blockData) {
        setBlock(blockData);
      }
      setRoom((await location.populate('room')) as Room);
      setPass((await location.populate('pass')) as Pass);
      setSurveyPoint((await location.populate('surveyPoint')) as SurveyPoint);
      setSequence((await location.populate('sequence')) as Sequence);
    } catch (error) {
      console.log(
        '🚀 ~ file: LocationDetailsComponent.tsx ~ line 114 ~ populatePanelReferences ~ error',
        error,
      );
    }
  };

  useEffect(() => {
    if (panelReferences && panelReferences[0] && location) {
      populatePanelReferences();
    }
  }, [panelReferences, location]);

  const panel = panelReferences[0];

  const panelMiningMethod = useMemo(() => {
    if (panel) {
      return panel.miningMethod;
    }
  }, [panel]);

  const getLocationStartDetails = () => {
    const roomName = room?.description.toLocaleUpperCase();

    if (isLanigan) {
      //
      if (roomName === 'PE' && (room?.description || block?.description || panel?.description)) {
        return (
          <>
            <Typography className={classes.secondaryTextRightPad} variant="caption">
              {room?.description || NOT_AVAILABLE}
            </Typography>
            <Typography className={classes.secondaryText} variant="caption">
              {block?.description || NOT_AVAILABLE}
            </Typography>
            <Typography className={classes.secondaryText} variant="caption">
              {panel?.description || NOT_AVAILABLE}
            </Typography>
          </>
        );
      }

      //
      if (roomName === 'MN' || roomName === 'BR' || roomName === 'DM' || roomName === 'DB') {
        return (
          <>
            <Typography className={classes.secondaryText} variant="caption">
              {room?.description || NOT_AVAILABLE}
            </Typography>
            <Typography className={classes.secondaryText} variant="caption">
              {panel?.description || NOT_AVAILABLE}
            </Typography>
          </>
        );
      }

      // Panel Block Room
      if (block?.description || panel?.description || room?.description) {
        return (
          <>
            <Typography className={classes.secondaryText} variant="caption">
              {block?.description || NOT_AVAILABLE}
            </Typography>
            <Typography className={classes.secondaryText} variant="caption">
              {panel?.description || NOT_AVAILABLE}
            </Typography>
            <Typography className={classes.secondaryText} variant="caption">
              {room?.description || NOT_AVAILABLE}
            </Typography>
          </>
        );
      }
    }

    if (isCory || isAllan || panelMiningMethod === MiningMethod.CHEVRON) {
      return (
        <>
          <Typography className={classes.secondaryText} variant="caption">
            {panel?.description || NOT_AVAILABLE}
          </Typography>
        </>
      );
    }

    if (isRocanville) {
      return (
        <>
          <Typography className={classes.secondaryText} variant="caption">
            {panel?.description && room?.description
              ? `${panel?.description || ''}-${room?.description || ''}`
              : NOT_AVAILABLE}
          </Typography>
        </>
      );
    }

    if (panelMiningMethod === MiningMethod.LONG_ROOM) {
      return (
        <>
          <Typography className={classes.secondaryText} variant="caption">
            {panel?.description || NOT_AVAILABLE}
          </Typography>
          <Typography className={classes.secondaryText} variant="caption">
            {room?.description || NOT_AVAILABLE}
          </Typography>
        </>
      );
    }
  };

  const getLocationSequencePassDetails = () => {
    if (isLanigan && (sequence || pass)) {
      // chevron type ONLY
      return (
        <>
          <GreenDotCharacter />
          <Typography
            className={`${classes.secondaryText} ${
              ignoreStepAndPassColors ? '' : classes.sequencePassText
            }`}
            variant="caption"
            style={{ paddingRight: '4px' }}
          >
            {sequence?.description || NOT_AVAILABLE}{' '}
          </Typography>
          <Typography
            className={`${classes.secondaryText} ${
              ignoreStepAndPassColors ? '' : classes.sequencePassText
            }`}
            variant="caption"
          >
            {pass?.description || NOT_AVAILABLE}
          </Typography>
        </>
      );
    }

    if (isVanscoy || isCory || isRocanville || isAllan) {
      return (
        <>
          {(sequence || pass) && <GreenDotCharacter />}
          {panelMiningMethod === MiningMethod.CHEVRON && (
            <Typography
              className={`${classes.secondaryText} ${
                ignoreStepAndPassColors ? '' : classes.sequencePassText
              }`}
              variant="caption"
              style={{ paddingRight: '4px' }}
            >
              {sequence?.description || NOT_AVAILABLE}{' '}
            </Typography>
          )}
          <Typography
            className={`${classes.secondaryText} ${
              ignoreStepAndPassColors ? '' : classes.sequencePassText
            }`}
            variant="caption"
          >
            {pass?.description || NOT_AVAILABLE}
          </Typography>
        </>
      );
    }
  };

  const getLocationDistance = (loc: LocationDocument | undefined) => {
    if (!loc) return '';

    const { startMeters } = loc;

    if (!startMeters && startMeters !== 0) return '';
    return (
      <>
        <GreenDotCharacter />
        <Typography className={classes.secondaryText} variant="caption">
          {useMeters
            ? `${Math.round(startMeters)} m${hazardLandmark ? `, ${hazardLandmark}` : ''}`
            : `${Math.round(startMeters * FEET_PER_METERS)} ${distanceUnitAbbreviation}`}
        </Typography>
      </>
    );
  };
  const locationDetails = getLocationStartDetails();

  return (
    <>
      <Grid item container xs={12} data-testid="location-details-component">
        <Grid item xs>
          {locationDetails && label && (
            <Typography className={classes.label} variant="caption">
              {label}:
            </Typography>
          )}
          {locationDetails}
          <Typography className={classes.secondaryText} variant="caption">
            {isAllan && <>&nbsp;</>}
            {surveyPoint?.description || NOT_AVAILABLE}
          </Typography>
          {getLocationSequencePassDetails()}
          {getLocationDistance(location)}
        </Grid>
      </Grid>
    </>
  );
};

export default observer(LocationDetailsComponent);
