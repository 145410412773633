import { PopulatedLocation } from '@/rxdb/Locations/useLocations';

export const sortRoomOptions = (options: PopulatedLocation[]) => {
  return options.sort((a, b) => {
    const aLabel = a.block?.description || '' + a.panel.description + a.room.description;
    const bLabel = b.block?.description || '' + b.panel.description + b.room.description;

    if (aLabel.toLowerCase() < bLabel.toLowerCase()) return -1;
    if (aLabel.toLowerCase() > bLabel.toLowerCase()) return 1;
    return 0;
  });
};
